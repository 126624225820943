import { useCognitoContext } from "components/Providers/CognitoProvider"
import { useRouter, useSearchParams } from "next/navigation"
import { toast } from "react-toastify"
import { getUserProfile } from "utils/requests/getUserProfile"

export interface SignInRedirectionActions {
  handleRedirection: (args?: {
    defaultRedirect?: boolean
    params?: URLSearchParams
  }) => void
  handleDeeplinkRedirection: (refreshToken: string) => void
  handleMarketplaceRedirection: () => void
  handleWebsiteRedirection: () => void
}

export interface SignInRedirectionHook {
  actions: SignInRedirectionActions
}

export function useSignInRedirection(): SignInRedirectionHook {
  const router = useRouter()
  const searchParams = useSearchParams()
  const { session, getCurrentUser } = useCognitoContext()

  const handleDeeplinkRedirection = (refreshToken: string) => {
    return new Promise<void>(resolve => {
      router.push(`voxietactics://callback?refreshToken=${refreshToken}`)

      const redirectTimout = setTimeout(() => {
        router.push(process.env.NEXT_PUBLIC_GAME_DOWNLOAD_URL as string)
      }, 1000)

      window.addEventListener("blur", () => {
        clearTimeout(redirectTimout)
        resolve()
      })
    })
  }

  const handleMarketplaceRedirection = async () => {
    const url = new URL(process.env.NEXT_PUBLIC_MARKETPLACE_URL as string)

    if (session) {
      try {
        const userProfile = await getUserProfile({
          accessToken: session.getAccessToken().getJwtToken(),
        })

        router.push(`${url.toString()}?walletType=${userProfile?.walletType}`)
      } catch (error) {
        console.error(error)
        router.push("/")
        toast.error("We encountered an error. Please try signing in again.")
      }
    } else {
      router.push("/")
    }
  }

  const handleMarketplaceLoginRedirection = async (
    params?: URLSearchParams
  ) => {
    const url = new URL(process.env.NEXT_PUBLIC_MARKETPLACE_URL as string)

    if (params) {
      url.search = params.toString()
    }

    router.push(url.toString())
  }

  const handleWebsiteRedirection = (params?: URLSearchParams) => {
    const url = new URL(process.env.NEXT_PUBLIC_WEBSITE_URL as string)
    if (params) {
      url.search = params.toString()
    }

    router.push(url.toString())
  }

  const handleRedirection = async ({
    defaultRedirect = true,
    params,
  }: { defaultRedirect?: boolean; params?: URLSearchParams } = {}) => {
    const { user, session } = await getCurrentUser({ force: true })

    if (user?.get("custom:walletConnected") !== "1") {
      router.push("/wallet-connect")
      return
    }

    const origin = localStorage.getItem("origin")
    const orgId = searchParams.get("orgId") as string
    const bundle = searchParams.get("bundle") as string

    switch (origin) {
      case "game":
        await handleDeeplinkRedirection(session?.getRefreshToken().getToken())
        break
      case "website":
        handleWebsiteRedirection(params)
        break
      case "marketplace":
        handleMarketplaceRedirection()
        break
      case "marketplace-login":
        if (!orgId || !bundle) {
          handleMarketplaceRedirection()
          return
        }

        if (params) {
          params.set("orgId", orgId)
          params.set("bundle", bundle)
        } else {
          params = new URLSearchParams({ orgId, bundle })
        }

        handleMarketplaceLoginRedirection(params)
        break
      default:
        if (defaultRedirect) {
          router.push("/actions")
        }
        break
    }
  }

  return {
    actions: {
      handleRedirection,
      handleDeeplinkRedirection,
      handleMarketplaceRedirection,
      handleWebsiteRedirection,
    },
  }
}
